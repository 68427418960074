var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"grey lighten-2"}},[_c('v-toolbar-title',[_vm._v(" Recibo Assinatura "),_c('span',{staticClass:"body-2"},[_c('i',[_vm._v(_vm._s(_vm.receiptSignature ? 'Editando' : 'Novo'))])])]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","bottom":"","indeterminate":""}}):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"initialDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data Inicial","hide-details":"auto","error-messages":errors,"append-icon":"mdi-calendar"},on:{"blur":function($event){_vm.fields.initialDate = _vm.dateFormatIso(_vm.initialDateFormated)},"click:append":function($event){_vm.initialDateMenu = true}},model:{value:(_vm.initialDateFormated),callback:function ($$v) {_vm.initialDateFormated=$$v},expression:"initialDateFormated"}})]}}],null,true)})]}}]),model:{value:(_vm.initialDateMenu),callback:function ($$v) {_vm.initialDateMenu=$$v},expression:"initialDateMenu"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.initialDateMenu = false}},model:{value:(_vm.fields.initialDate),callback:function ($$v) {_vm.$set(_vm.fields, "initialDate", $$v)},expression:"fields.initialDate"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"finalDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data Final","hide-details":"auto","error-messages":errors,"append-icon":"mdi-calendar"},on:{"blur":function($event){_vm.fields.finalDate = _vm.dateFormatIso(_vm.finalDateFormated)},"click:append":function($event){_vm.finalDateMenu = true}},model:{value:(_vm.finalDateFormated),callback:function ($$v) {_vm.finalDateFormated=$$v},expression:"finalDateFormated"}})]}}],null,true)})]}}]),model:{value:(_vm.finalDateMenu),callback:function ($$v) {_vm.finalDateMenu=$$v},expression:"finalDateMenu"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.finalDateMenu = false}},model:{value:(_vm.fields.finalDate),callback:function ($$v) {_vm.$set(_vm.fields, "finalDate", $$v)},expression:"fields.finalDate"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"signature"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","accept":"image/*","hide-details":"auto","label":"Assinatura","hint":"Tamanho ideal: 100x50","persistent-hint":"","error-messages":errors},model:{value:(_vm.fields.signature),callback:function ($$v) {_vm.$set(_vm.fields, "signature", $$v)},expression:"fields.signature"}})]}}])}),_c('v-img',{attrs:{"max-width":"100px","src":_vm.fields.signatureBase64}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":"","loading":_vm.loadingSave},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-content-save')}}),_vm._v(" Salvar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }