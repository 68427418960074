export default {

  ptBr (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  },

  ptBrMonth(date) {
    if (!date) return null

    const [year, month] = date.split('-')
    return `${month}/${year}`
  },

  iso (date) {
    if (!date) return null

    const [day, month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },

  isoMonth(date) {
    if (!date) return null

    const [month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}`
  },

}
