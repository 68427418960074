<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Recibo Assinatura
          <span
            class="body-2"
          >
            <i>{{ receiptSignature ? 'Editando' : 'Novo' }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">

          <v-row>
            <v-col>
              <v-menu
                v-model="initialDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="initialDate"
                  >
                    <v-text-field
                      v-model="initialDateFormated"
                      v-mask="'##/##/####'"
                      label="Data Inicial"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="fields.initialDate = dateFormatIso(initialDateFormated)"
                      @click:append="initialDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="fields.initialDate"
                  no-title
                  class="ma-0"
                  @input="initialDateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="finalDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="finalDate"
                  >
                    <v-text-field
                      v-model="finalDateFormated"
                      v-mask="'##/##/####'"
                      label="Data Final"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="fields.finalDate = dateFormatIso(finalDateFormated)"
                      @click:append="finalDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="fields.finalDate"
                  no-title
                  class="ma-0"
                  @input="finalDateMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="fields.name"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="signature"
              >
                <v-file-input
                  v-model="fields.signature"
                  prepend-icon=""
                  accept="image/*"
                  hide-details="auto"
                  label="Assinatura"
                  hint="Tamanho ideal: 100x50"
                  persistent-hint
                  :error-messages="errors"
                />
              </validation-provider>

              <v-img
                max-width="100px"
                :src="fields.signatureBase64"
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import receiptsSignaturesApi from '@/api/receipts-signatures'
  import dateFormat from '@/utils/date-format'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      receiptSignature: {
        type: Object,
        default: () => { },
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        initialDateMenu: false,
        finalDateMenu: false,
        initialDateFormated: null,
        finalDateFormated: null,
        dateFormatIso: dateFormat.iso,
        fields: {}
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
      'fields.initialDate': {
        immediate: true,
        handler (newVal) {
          this.initialDateFormated = dateFormat.ptBr(newVal)
        },
      },
      'fields.finalDate': {
        immediate: true,
        handler (newVal) {
          this.finalDateFormated = dateFormat.ptBr(newVal)
        },
      },
    },

    methods: {

      async load () {
        this.fields = {
          initialDate: null,
          finalDate: null,
        }

        if (this.receiptSignature) {
          this.fields = { ...this.receiptSignature }
        }
      },

      async save () {
        this.loadingSave = true
        this.$refs.observer.reset()

        try {
          const signature = { ...this.fields }

          if (signature.signature) {
            signature.signature = await this.toBase64(signature.signature)
          } else {
            signature.signature = signature.signatureBase64
          }


          if (this.receiptSignature) {
            await receiptsSignaturesApi.update(this.receiptSignature.id, signature)
          } else {
            await receiptsSignaturesApi.insert(signature)
          }

          this.$snackbar.show({ color: 'success', message: 'Salvo com sucesso' })
          this.show = false
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
          return
        } finally {
          this.loadingSave = false
        }
      },

      async toBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        })
      },

    },

  }
</script>
